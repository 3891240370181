import React, { useEffect } from 'react';
import { stateUS } from '../../../../../helpers/state';
import Loading from '../../../../../components/loading/Loading';
import SidebarModal from '../../../../../components/sidebarModal/SidebarModal';
import { Select, Input, Button, Form, Row, Col, Checkbox } from 'antd';

export const UpdateFormAllSettings = ({ updateProps, loadingUser, updateModalFormRef, updateFormFields, onChange, toggleNewPassword, editBtnLoader, handleOnFinish, setToggleUpdate, roles, setBillingAddrress, handleChangeShipping, shippingForm, userShippingAddress, userBillingAddress, handleChangeBilling, billingForm, handleChangeShipState, handleChangeBillState, setUserShippingAddress, setUserBillingAddress, setShippingForm, setBillingForm }) => {
	const shippingAddress = updateFormFields.find((field) => field.name === 'shippingAddress')?.value || {};
	const billingAddress = updateFormFields.find((field) => field.name === 'billingAddress')?.value || {};
	const useShippingAsBilling = updateFormFields.find((field) => field.name === 'useShippingAsBilling')?.value || false;
	const verified = updateFormFields.find((field) => field.name === 'verified')?.value;
	
	useEffect(() => {
		setShippingForm({
			shippingAddress: '',
			shippingAddress2: '',
			shippingCity: '',
			shippingState: '',
			shippingZipCode: '',
			shippingPhone: '',
		});
		setBillingForm({
			billingAddress: '',
			billingAddress2: '',
			billingCity: '',
			billingState: '',
			billingZipCode: '',
			billingPhone: '',
		});
	}, []);
	const handleCloseModal = () => {
		// Reset the form fields
		setUserShippingAddress({
			address: '',
			address2: '',
			city: '',
			state: '',
			phone: '',
			zip_code: '',
		});
		setUserBillingAddress({
			address: '',
			address2: '',
			city: '',
			state: '',
			phone: '',
			zip_code: '',
		});
		setShippingForm({
			shippingAddress: '',
			shippingAddress2: '',
			shippingCity: '',
			shippingState: '',
			shippingZipCode: '',
			shippingPhone: '',
		});
		setBillingForm({
			billingAddress: '',
			billingAddress2: '',
			billingCity: '',
			billingState: '',
			billingZipCode: '',
			billingPhone: '',
		});

		// Close the modal
		setVisible(false);
	};
	return (
		<SidebarModal {...updateProps} onCancel={handleCloseModal} className="sidebar__modal--right" title="Edit User">
			{!loadingUser ? (
				<Form
					name="update"
					autoComplete="off"
					ref={updateModalFormRef}
					wrapperCol={{ span: 24 }}
					initialValues={{ remember: false }}
					onFinish={(data) => {
						handleOnFinish({ name: 'update', data: data });
						console.log('Shipping Form Reset:', shippingForm, userShippingAddress);
						console.log('Billing Form Reset:', billingForm, userBillingAddress);
					}}
					fields={updateFormFields}
				>
					<Form.Item
						name="firstName"
						label="First Name:"
						rules={[
							{
								required: true,
								message: 'Please input your first name!',
							},
						]}
					>
						<Input disabled placeholder="First Name:" />
					</Form.Item>
					<Form.Item
						name="lastName"
						label="Last Name:"
						rules={[
							{
								required: true,
								message: 'Please input your last name!',
							},
						]}
					>
						<Input disabled placeholder="Last Name:" />
					</Form.Item>
					<Form.Item
						name="email"
						label="Email:"
						rules={[
							{ required: true, message: 'Please input your email!' },
							{
								pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
								message: 'Invalid email address',
							},
						]}
					>
						<Input placeholder="Email:" value="" type="email" />
					</Form.Item>

					<Form.Item name="status" label="Status:" rules={[{ required: false, message: 'Please input your Status!' }]} style={{ display: 'none' }}>
						<Input placeholder="Status:" />
					</Form.Item>
					<h2>Shipping Address</h2>
					<Form.Item label="Address:" required="true" rules={[{ required: true, message: 'Please input your shipping address!' }]}>
						<Input placeholder="Shipping Address:" name="shippingAddress" value={shippingForm.shippingAddress === '' ? userShippingAddress?.address : shippingForm.shippingAddress} onChange={handleChangeShipping} required="true" />
					</Form.Item>
					<Form.Item label="Address2:" rules={[{ message: 'Please input your shipping address!' }]}>
						<Input placeholder="Shipping Address:" name="shippingAddress2" value={shippingForm.shippingAddress2 === '' ? userShippingAddress?.address2 : shippingForm.shippingAddress2} onChange={handleChangeShipping} />
					</Form.Item>
					<Form.Item label="City:" required="true" rules={[{ required: true, message: 'Please input your city!' }]}>
						<Input placeholder="City:" name="shippingCity" value={shippingForm.shippingCity === '' ? userShippingAddress?.city : shippingForm.shippingCity} onChange={handleChangeShipping} required="true" />
					</Form.Item>
					<Form.Item label="Select State:" required="true" rules={[{ required: true, message: 'Please select your state!' }]}>
						<Select name="shippingState" value={shippingForm.shippingState === '' ? userShippingAddress?.state : shippingForm.shippingState} onChange={handleChangeShipState} required="true">
							<Option value="">Select State</Option>
							{stateUS.map((item) => (
								<Option key={item.abbreviation} value={item.abbreviation}>
									{item.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label="Zip Code:" required="true" rules={[{ required: true, message: 'Please input your zip code!' }]}>
						<Input placeholder="Zip Code:" name="shippingZipCode" value={shippingForm.shippingZipCode === '' ? userShippingAddress?.zip_code : shippingForm.zip_code} onChange={handleChangeShipping} required="true" />
					</Form.Item>
					<Form.Item label="Phone:" required="true" rules={[{ required: true, message: 'Please input your phone!' }]}>
						<Input placeholder="Phone:" name="shippingPhone" value={shippingForm.shippingPhone === '' ? userShippingAddress?.phone : shippingForm.phone} onChange={handleChangeShipping} required="true" />
					</Form.Item>

					<Form.Item name="useShippingAsBilling" valuePropName="checked">
						<Checkbox checked={billingAddress} onChange={(e) => setBillingAddrress(e.target.checked)}>
							Use Shipping Address as Billing Address
						</Checkbox>
					</Form.Item>

					{!useShippingAsBilling && (
						<>
							<h2>Billing Address</h2>
							<Form.Item label="Address:" required="true" rules={[{ required: true, message: 'Please input your billing address!' }]}>
								<Input placeholder="Billing Address:" name="billingAddress" value={billingForm.billingAddress === '' ? userBillingAddress?.address : billingForm.address} onChange={handleChangeBilling} required="true" />
							</Form.Item>
							<Form.Item label="Address2:" rules={[{ message: 'Please input your billing address!' }]}>
								<Input placeholder="Billing Address:" name="billingAddress2" value={billingForm.billingAddress2 === '' ? userBillingAddress?.address2 : billingForm.billingAddress2} onChange={handleChangeBilling} />
							</Form.Item>
							<Form.Item label="City:" required="true" rules={[{ required: true, message: 'Please input your billing city!' }]}>
								<Input placeholder="City:" name="billingCity" value={billingForm.billingCity === '' ? userBillingAddress?.city : billingForm.city} onChange={handleChangeBilling} required="true" />
							</Form.Item>
							<Form.Item label="Select State:" required="true" rules={[{ required: true, message: 'Please select your billing state!' }]}>
								<Select name="billingState" value={billingForm.billingState === '' ? userBillingAddress?.state : billingForm.billingState} onChange={handleChangeBillState} required="true">
									<Option value="">Select State</Option>
									{stateUS.map((item) => (
										<Option key={item.abbreviation} value={item.abbreviation}>
											{item.name}
										</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label="Zip Code:" required="true" rules={[{ required: true, message: 'Please input your billing zip code!' }]}>
								<Input placeholder="Zip Code:" name="billingZipCode" value={billingForm.billingZipCode === '' ? userBillingAddress?.zip_code : billingForm.zip_code} onChange={handleChangeBilling} required="true" />
							</Form.Item>
							<Form.Item label="Phone:" required="true" rules={[{ required: false, message: 'Please input your phone!' }]}>
								<Input placeholder="Phone:" name="billingPhone" value={billingForm.billingPhone === '' ? userBillingAddress?.phone : billingForm.phone} onChange={handleChangeBilling} required="true" />
							</Form.Item>
						</>
					)}
					<Form.Item name="friendsAndFamily" valuePropName="checked" className="initial-height">
						<Checkbox checked={updateFormFields.checked}>Add friends and family</Checkbox>
					</Form.Item>
					<Form.Item name="verified" className="initial-height" valuePropName="checked">
						<Checkbox checked={!!verified}>Verified</Checkbox>
					</Form.Item>
					<Form.Item className="initial-height">
						<Checkbox onChange={onChange} checked={toggleNewPassword}>
							Create new password
						</Checkbox>
					</Form.Item>
					{toggleNewPassword === true && (
						<Form.Item
							name="newPassword"
							label="Password:"
							initialValue={''}
							rules={[
								{
									required: true,
									message: 'Please input your password!',
								},
							]}
						>
							<Input.Password placeholder="Password:" />
						</Form.Item>
					)}
					<Form.Item name="updateUserId" style={{ display: 'none' }}>
						<Input type="hidden" />
					</Form.Item>
					<Form.Item name="role" style={{ display: 'none' }}>
						<Select>
							<Option value="">Select Roles</Option>

							{roles.map((item) => {
								return (
									<Option key={item.name} value={item.value}>
										{item.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
					<Form.Item className="ant-col-btn" wrapperCol={{ offset: 8 }}>
						<Row gutter={16}>
							<Col span={12}>
								<Button
									type="primary"
									htmlType="button"
									className="btn btn-primary-link btn-block"
									onClick={() => {
										setToggleUpdate(false);
										setUserShippingAddress({
											address: '',
											address2: '',
											city: '',
											state: '',
											phone: '',
											zip_code: '',
										});
										setBillingAddrress({
											address: '',
											address2: '',
											city: '',
											state: '',
											phone: '',
											zip_code: '',
										});
										setShippingForm({
											shippingAddress: '',
											shippingAddress2: '',
											shippingCity: '',
											shippingState: '',
											shippingZipCode: '',
											shippingPhone: '',
										});
										setBillingForm({
											shippingAddress: '',
											shippingAddress2: '',
											shippingCity: '',
											shippingState: '',
											shippingZipCode: '',
											shippingPhone: '',
										});
										console.log('Shipping Form Reset:', shippingForm, userShippingAddress);
										console.log('Billing Form Reset:', billingForm, userBillingAddress);
									}}
								>
									<span>Cancel</span>
								</Button>
							</Col>
							<Col span={12}>
								{!editBtnLoader ? (
									<Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
										<span>Save User</span>
									</Button>
								) : (
									<Button type="button" className="btn btn-primary-outline btn-block">
										<span>Editing...</span>
									</Button>
								)}
							</Col>
						</Row>
					</Form.Item>
				</Form>
			) : (
				<Loading />
			)}
		</SidebarModal>
	);
};
