import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Select, Button, notification } from 'antd';
import Table from './Table';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { Main, Section } from '../../../components/content';
import { notificationMessages } from '../../../helpers/notificationMessages';
import { jwtDecode } from 'jwt-decode';
import { getGroceryPermissions } from '../../../middleware';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { CreateForm } from './create-form/CreateForm';
import { UpdateFormAllSettings } from './update-form/update-form-all-settings/UpdateFormAllSettings';
import { UpdateUserStatusSingleChanges } from './update-form/update-user-status-single-changes/UpdateUserStatusSingleChanges';
import { UpdateUserStatusMultiChanges } from './update-form/update-user-status-multi-changes/UpdateUserStatusMultiChanges';
import { DeleteForm } from './delete-form/DeleteForm';
import { BlockForm } from './block-form/BlockForm';
import { ViewUserData } from './view-user/ViewUserData';

const options = [5, 10, 20, 50, 100];
const { Option } = Select;

const Users = () => {
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);
	const [list, setList] = useState([]);
	const [refetch, setRefetch] = useState(false);
	const [loading, setLoading] = useState(true);
	const [fetchedData, setFetchedData] = useState([]);
    const navigate = useNavigate();
	const [userShippingAddress, setUserShippingAddress] = useState({
		address: '',
		address2: '',
		city: '',
		state: '',
		phone: '',
		zip_code: '',
	});
	const [userBillingAddress, setUserBillingAddress] = useState({
		address: '',
		address2: '',
		city: '',
		state: '',
		phone: '',
		zip_code: '',
	});

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);
  
  // Preview seller after redicreciton from Asks
  useEffect(()=> {
    const { sellerId } = location?.state || '';
    sellerId && handleToggle({ name: 'view', state: viewProps, data: { _id: sellerId } });
  },[])


	// LOADER PROTECTION
	const [createBtnLoader, setCreateBtnLoader] = useState(false);
	const [editBtnLoader, setEditBtnLoader] = useState(false);
	const [deactivateBtnLoader, setDeactivateBtnLoader] = useState(false);
	const [deactivateBlkBtnLoader, setDeactivateBlkBtnLoader] = useState(false);
	const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
	const [blockBtnLoader, setBlockBtnLoader] = useState(false);
	const [billingAddress, setBillingAddrress] = useState(false);
	const [userAddresses, setUserAddresses] = useState([]);

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [filterStatus, setFilterStatus] = useState(null);

	const handleOnSubmitSearch = (e) => {
		e.preventDefault();
		setSearch(searchForm.query);
		setRefetch((prevState) => !prevState);
	};

	const handleOnChangeSearch = (value) => {
		setSearchForm({ query: value });
	};

	const handleOnClearSearch = () => {
		setSearch(null);
		setSearchForm({ query: '' });
		setRefetch((prevState) => !prevState);
	};

	const getTableData = useCallback(async () => {
		const { token } = user;
		setLoading(true);
		try {
			await axios
        .get(
          `${SERVER_URL}/${AXIOS_API_CALL.getCustomers}/${currentPage}/${limitPage}/${encodeURIComponent(search) || null}/${filterStatus || null}`,

					{
						withCredentials: false,
						headers: { Authorization: `Bearer ${token}` },
					}
				)
				.then((res) => {
					setList(res?.data);
					setTimeout(() => {
						setLoading(false);
					}, 700);
				})
				.catch((err) => {
					setLoading(false);
					console.error(err);
				});
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	}, [user, currentPage, limitPage, search, filterStatus]);


	const getViewData = async (id) => {
		try {
			const { token } = user;
			const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getCustomers}/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const customerData = response.data.data[0];
			setViewData(customerData);
			setUpdateUser(customerData);
			setUserAddresses(customerData.address);

			if (customerData?.address?.length > 0) {
				setUserShippingAddress(customerData?.address[0]);
				setBillingAddrress(customerData?.address[1]);
			}

			setBillingAddrress(response.data.useShippingAsBilling);
		} catch (error) {
			console.error(error);
		}
	};

	// FETCH DATA FOR `TABLE`
	useEffect(() => {
		getTableData();
	}, [getTableData, refetch]);

  useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const page = params.get('page');
      if (page) {
        setCurrentPage(parseInt(page, 10));
      }
    }, []);
  
    useEffect(() => {
      // Save page number to localStorage
      console.log(currentPage);
      localStorage.setItem('currentPage', currentPage);
    }, [currentPage]);
  
    useEffect(() => {
      navigate(`?page=${currentPage}`);
    }, [currentPage, navigate]);
  

	useEffect(() => {
		if (!!search) {
			setCurrentPage(1);
		}
	}, [search]);


	// FILTER STATUS
	const handleStatusFilter = (value) => {
		setFilterStatus(value);
		setRefetch((prevState) => !prevState);
	};

	// Button (deactive, delete...) in section header - change status if checked one or more to the Enable button
	const [disabledButton, setDisabledButton] = useState(false);
	const handleDisabledButton = (status) => {
		setDisabledButton(status);
	};

	// Get selected rows id one or more
	const [checkedRow, setCheckedRow] = useState([]);
	const handleCheckedRows = (data) => {
		setCheckedRow(data);
	};

	const [userUpdateId, setUserUpdateId] = useState('');
	const [userTestUpdateId, setUserTestUpdateId] = useState({
		id: '',
	});
	const [userDeleteId, setUserDeleteId] = useState('');
	const [userBlockId, setUserBlockId] = useState('');
	const [userStatusSingleId, setUserStatusSingleId] = useState({
		id: '',
		status: '',
	});

	const [viewData, setViewData] = useState([]);
	const [viewLoading, setViewLoading] = useState(false);
	const [toggleView, setToggleView] = useState(false);

	const [userStatusMultiId, setUserStatusMultiId] = useState([]);

	const handleToggle = (props) => {
		const { name, state, data } = props;
		switch (name) {
			case 'create':
				state.onToggle((prevState) => !prevState);
				break;
			case 'view':
				getViewData(data._id);
				state.onToggle((prevState) => !prevState);
				break;
			case 'update':
				setUserUpdateId(data);
				getViewData(data._id);
				setToggleUpdate(true);
				state.onToggle((prevState) => !prevState);

			case 'status':
				setStatusSingleEmoloyee(data);
				state.onToggle((prevState) => !prevState);
				break;
			case 'updateStatusMulti':
				setUserStatusMultiId(data);
				state.onToggle((prevState) => !prevState);
				break;
			case 'delete':
				setEmployeeDeleteId(data);
				state.onToggle((prevState) => !prevState);
				break;
			case 'block':
				setBlockUser(data);
				state.onToggle((prevState) => !prevState);
				break;
			default:
				console.error('handleToggle ---- error');
		}
	};

	// Refs
	const createModalFormRef = useRef(null);
	const updateModalFormRef = useRef(null);
	const deleteModalFormRef = useRef(null);
	const statusModalSingleFormRef = useRef(null);
	const blockModalFormRef = useRef(null);
	const statusModalMultiFormRef = useRef(null);

	// State
	const [toggleCreate, setToggleCreate] = useState(false);
	const [toggleUpdate, setToggleUpdate] = useState(false);
	const [toggleDelete, setToggleDelete] = useState(false);
	const [toggleBlock, setToggleBlock] = useState(false);
	const [toggleStatusSingle, setToggleStatusSingle] = useState(false);
	const [toggleStatusMulti, setToggleStatusMulti] = useState(false);

	// Store
	const [createUser, setCreateUser] = useState([]);
	const [updateUser, setUpdateUser] = useState([]);
	const [deleteUser, setDeleteUser] = useState([]);
	const [blockUser, setBlockUser] = useState([]);
	const [statusSingleUser, setStatusSingleEmoloyee] = useState([]);
	const [statusMultiUser, setStatusMultiEmoloyee] = useState([]);
	const [toggleNewPassword, setToggleNewPassword] = useState(false);
	const [employeeDeleteId, setEmployeeDeleteId] = useState('');

	const onChange = (e) => {
		if (e.target.checked) {
			setToggleNewPassword(true);
		} else {
			setToggleNewPassword(false);
		}
	};


	const [shippingForm, setShippingForm] = useState({
		shippingAddress: '',
		shippingAddress2: '',
		shippingCity: '',
		shippingState: '',
		shippingZipCode: '',
		shippingPhone: '',
	});

	const [billingForm, setBillingForm] = useState({
		billingAddress: '',
		billingAddress2: '',
		billingCity: '',
		billingState: '',
		billingZipCode: '',
		billingPhone: '',
	});

	const handleChangeShipping = (e) => {
		const { name, value } = e.target;
		console.log(`Changing ${name} to ${value}`);

		// If the value is empty, you can choose to delete the property or set it to an empty string
		setShippingForm((prevForm) => ({
			...prevForm,
			[name]: value === '' ? undefined : value, // Use undefined to delete the property
		}));
	};

	const handleChangeBilling = (e) => {
		const { name, value } = e.target;

		// Similar handling for billing form
		setBillingForm((prevForm) => ({
			...prevForm,
			[name]: value === '' ? undefined : value, // Use undefined to delete the property
		}));
	};

	const handleChangeShipState = (value, option, name) => {
		setShippingForm((prevForm) => ({
			...prevForm,
			shippingState: value,
		}));
	};

	const handleChangeBillState = (value, option, name) => {
		setBillingForm((prevForm) => ({
			...prevForm,
			billingState: value,
		}));
	};


	// FETCH ROLES
	const [roles, setRoles] = useState([]);

	useEffect(() => {
		const { token } = user;

		try {
			axios
				.get(
					`${SERVER_URL}/${AXIOS_API_CALL.getRoles}/1/5/null`,

					{
						withCredentials: false,
						headers: { Authorization: `Bearer ${token}` },
					}
				)
				.then((res) => {
					setRoles(res.data?.data.Data);
				})
				.catch((err) => console.error(err))
				.finally(setTimeout(() => {}, 700));
		} catch (err) {
			console.error(err);
		}
	}, [user]);

	const [loadingUser, setLoadingUser] = useState(false);

	// FETCH UPDATE DATA
	useEffect(() => {
		const userUpdateId = userTestUpdateId.id;
		const { token } = user;

		if (!!userUpdateId) {
			try {
				setLoadingUser(true);
				axios
					.post(
						`${SERVER_URL}/${AXIOS_API_CALL.updateCustomers}/${userUpdateId}`,
						{ department: PERMISSIONS.grocery },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					)
					.then((res) => {
						setUpdateUser(res.data.data);
						setTimeout(() => {
							setLoadingUser(false);
						}, 700);
					})
					.catch((err) => {
						setLoadingUser(false);
						console.error(err);
					});
			} catch (err) {
				console.error(err);
			}
		}
	}, [user, userTestUpdateId, refetch]);

	const handleOnFinish = async (props) => {
		const { name, data } = props;
		const { token } = user;


		switch (name) {
			case 'create':
				setCreateBtnLoader(true);
				try {
					const createData = {
						firstName: data?.firstName || '',
						lastName: data?.lastName || '',
						user_role: data?.role || '',
						address: [
							{
								address: data?.address || '',
								zip_code: data?.zipCode || '',
								state: data?.state || '',
								city: data?.city || '',
							},
						],
						password: data?.password || '',
						phone: data?.phone || '',
						email: data?.email || '',
						userType: 'dashboard',
						department: PERMISSIONS.grocery,
					};

					let fetchData = await axios.post(
						`${SERVER_URL}/${AXIOS_API_CALL.createUser}`,
						{ ...createData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					if (fetchData.status === 201) {
						setRefetch((prevState) => !prevState);
						setToggleCreate(false);
						setCreateUser([]);

						createModalFormRef.current.resetFields();

						// notification
						notification.success({
							message: notificationMessages.successCreateUser,
							placement: 'bottomLeft',
						});
					}
					setTimeout(() => {
						setCreateBtnLoader(false);
					}, 700);
				} catch (err) {
					setCreateBtnLoader(false);
					console.error(err);

					notification.error({
						message: notificationMessages.userAlreadyExists,
						placement: 'bottomLeft',
					});
				}
				break;
			case 'update':
				setEditBtnLoader(false);
				try {
					const newPassword = Object.keys(data).includes('newPassword');
					const userId = data?.updateUserId;
					const friendsAndFamily = data.friendsAndFamily;
					const roleId = roles.find((role) => role.name === data?.role)?._id || '';

					let currentVerifiedStatus = data.verified;


					// Determine new status
					let newStatus = currentVerifiedStatus ? 'verified' : 'none';
					
					let userType = 'buyer';
					if (newStatus === 'verified') {
						userType = 'seller';
					}
					let updateData = {};
					// Date to backend --

					updateData = updateData = {
						password: data?.password,
						email: data?.email || '',
						friendsAndFamily: friendsAndFamily,
						verified: newStatus,
						addresses: {
							useShippingAsBilling: billingAddress,
							shippingAddress: {
								address: shippingForm.shippingAddress === '' ? userShippingAddress.address : shippingForm.shippingAddress,
								address2: shippingForm.shippingAddress2 === '' ? userShippingAddress?.address2 : shippingForm.shippingAddress2,
								zip_code: shippingForm.shippingZipCode === '' ? userShippingAddress.zip_code : shippingForm.shippingZipCode,
								state: shippingForm.shippingState === '' ? userShippingAddress.state : shippingForm.shippingState,
								city: shippingForm.shippingCity === '' ? userShippingAddress.city : shippingForm.shippingCity,
								isDefault: data.isDefault || false,
								userFullName: data.firstName + ' ' + data.lastName || '',
								phone: shippingForm.shippingPhone === '' ? userShippingAddress?.phone : shippingForm.shippingPhone,
								userType: userType,
							},
							...(billingAddress !== true && {
								billingAddress: {
									address: billingForm.billingAddress === '' ? userBillingAddress.address : billingForm.billingAddress,
									address2: billingForm.billingAddress2 === '' ? userBillingAddress.address2 : billingForm.billingAddress2,
									zip_code: billingForm.billingZipCode === '' ? userBillingAddress.zip_code : billingForm.billingZipCode,
									state: billingForm.billingState === '' ? userBillingAddress.state : billingForm.billingState,
									city: billingForm.billingCity === '' ? userBillingAddress.city : billingForm.billingCity,
									isDefault: billingForm.isDefault || false,
									userFullName: data.firstName + ' ' + data.lastName || '',
									phone: billingForm.billingPhone === '' ? userBillingAddress.phone : billingForm.billingPhone,
									userType: userType,
								},
							}),
						},
					};
					const payload = {
						...updateData,
						...(billingAddress !== true && { billingAddress }), // Only include billingAddress if it's not true
						...(newPassword && { newPassword }), // Only include newPassword if it exists
					};

					const userUpdateResponse = await axios.post(
						`${SERVER_URL}/${AXIOS_API_CALL.updateCustomers}/${userId}`,
						{ ...updateData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);
					const isEmailChanged = userUpdateResponse.data.message.isEmailChanged;
					if (userUpdateResponse.request.status === 200) {
						setTimeout(() => {
							setEditBtnLoader(false);
						}, 700);
					}
					setRefetch((prevState) => !prevState);
					setToggleUpdate(false);
					setUpdateUser([]);
					setUserTestUpdateId({ id: '' });

					setTimeout(() => {
						updateModalFormRef.current.resetFields();
					}, 750);

					const notificationMessage = isEmailChanged ? "User's email is changed" : 'User  successfully modified!';
					notification.success({
						message: notificationMessage,
						placement: 'bottomLeft',
					});
				} catch (err) {
					console.error(err);
					setEditBtnLoader(false);
					notification.error({
						message: err.response.data.error || err.response.data.message,
						placement: 'bottomLeft',
					});
				}
				break;
			case 'status':
				try {
					const userId = data.statusSingleUserId[0]?.id;
					let currentStatus = data.statusSingleUserId[0]?.status;

					// Determine new status
					let newStatus = currentStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
					const userStatusData = {
						...data,
						status: newStatus,
					};
					await axios.post(
						`${SERVER_URL}/${AXIOS_API_CALL.updateCustomers}/${userId}`,
						{ ...userStatusData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					setRefetch((prevState) => !prevState);

					setToggleStatusSingle(false);
					setStatusSingleEmoloyee([]);
					setFilterStatus(null);
					setUserStatusSingleId({
						id: '',
						status: '',
					});

					statusModalSingleFormRef.current.resetFields();

					notification.success({
						message: 'User status changed successfully!',
						placement: 'bottomLeft',
					});
					setTimeout(() => {
						setDeactivateBtnLoader(false);
					}, 700);
				} catch (err) {
					console.error(err);
					setDeactivateBtnLoader(false);
				}
				break;
			case 'updateStatusMulti':
				setDeactivateBlkBtnLoader(true);
				try {
					const deactiveMultiData = {
						users: data?.statusMultiUser,
						department: PERMISSIONS.grocery,
					};

					await axios.post(
						`${SERVER_URL}/${AXIOS_API_CALL.usersBulkDeactivateUser}`,
						{ ...deactiveMultiData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					setRefetch((prevState) => !prevState);
					setToggleStatusMulti(false);
					setStatusMultiEmoloyee([]);
					setFilterStatus(null);

					statusModalMultiFormRef.current.resetFields();

					notification.success({
						message: notificationMessages.successUpdateStatusMultiUser,
						placement: 'bottomLeft',
					});
					setTimeout(() => {
						setDeactivateBlkBtnLoader(false);
					}, 700);
				} catch (err) {
					console.error(err);
					setDeactivateBlkBtnLoader(false);
				}
				break;
			case 'delete':
				setDeleteBtnLoader(true);
				try {
					const userId = data?.deleteUserId;

					await axios.delete(
						`${SERVER_URL}/${AXIOS_API_CALL.deleteCustomers}/${userId}`,

						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					setRefetch((prevState) => !prevState);
					setToggleDelete(false);
					setDeleteUser([]);
					setUserDeleteId('');
					setEmployeeDeleteId('');
					deleteModalFormRef.current.resetFields();

					notification.success({
						message: 'User is deleted.',
						placement: 'bottomLeft',
					});
					setTimeout(() => {
						setDeleteBtnLoader(false);
					}, 700);
				} catch (err) {
					notification.error({
						message: 'Failed to delete user',
						placement: 'bottomLeft',
					});
					console.error(err);
					setDeleteBtnLoader(false);
				}
				break;
			case 'block':
				try {
					const userId = data.blockUserId;
					let newStatus = 'BLOCKED';
					const userStatusData = {
						...data,
						status: newStatus,
					};
					await axios.post(
						`${SERVER_URL}/${AXIOS_API_CALL.updateCustomers}/${userId}`,
						{ ...userStatusData },
						{
							withCredentials: false,
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					setRefetch((prevState) => !prevState);

					setToggleBlock(false);
					setStatusSingleEmoloyee([]);
					setFilterStatus(null);
					setUserStatusSingleId({
						id: '',
						status: '',
					});

					statusModalSingleFormRef.current.resetFields();

					notification.success({
						message: notificationMessages.successUpdateStatusRole,
						placement: 'bottomLeft',
					});
					setTimeout(() => {
						setDeactivateBtnLoader(false);
					}, 700);
				} catch (err) {
					console.error(err);
					setDeactivateBtnLoader(false);
				}
				break;
			default:
				console.error('handleOnFinish ---- error');
		}
	};

	// Create User
	const createProps = {
		active: toggleCreate,
		onToggle: setToggleCreate,
		formRef: createModalFormRef,
		data: createUser,
		onData: setCreateUser,
	};

	// Update User
	const updateProps = {
		active: toggleUpdate,
		onToggle: setToggleUpdate,
		formRef: updateModalFormRef,
		data: updateUser,
		onData: setUpdateUser,
		loading: loadingUser,
	};

	const viewProps = {
		active: toggleView,
		onToggle: setToggleView,
		loading: viewLoading,
		data: viewData,
	};
	const handleClose = () => {
		setToggleView(false);
	};

	// Delete User
	const deleteProps = {
		active: toggleDelete,
		onToggle: setToggleDelete,
		formRef: deleteModalFormRef,
		data: deleteUser,
		onData: setDeleteUser,
	};

	// Delete User
	const blockProps = {
		active: toggleBlock,
		onToggle: setToggleBlock,
		formRef: blockModalFormRef,
		data: blockUser,
		onData: setBlockUser,
	};

	// Status Single Update User
	const statusSingleProps = {
		active: toggleStatusSingle,
		onToggle: setToggleStatusSingle,
		formRef: statusModalSingleFormRef,
		data: statusSingleUser,
		onData: setStatusSingleEmoloyee,
	};

	// Status Multi Update User
	const statusMultiProps = {
		active: toggleStatusMulti,
		onToggle: setToggleStatusMulti,
		formRef: statusModalMultiFormRef,
		data: statusMultiUser,
		onData: setStatusMultiEmoloyee,
	};

	// TABLE
	const tableProps = {
		handleDisabledButton: handleDisabledButton,
		handleCheckedRows: handleCheckedRows,
	};

	const tableActionsProps = {
		// custom for this table - always changes for next implementation
		handleToggle: handleToggle,
		viewActions: viewProps,
		updateActions: updateProps,
		deleteActions: deleteProps,
		statusActions: statusSingleProps,
		blockActions: blockProps,
		location: location,
		groceryPermissions: groceryPermissions,
		getGroceryPermissions: getGroceryPermissions,
	};

	const tableFiltersProps = {
		handleStatusFilter: handleStatusFilter,
	};

	// Table Pagination
	const paginationProps = {
		current: currentPage,
		setCurrentPage: setCurrentPage,
		limitPage: limitPage,
		buttonLimit: 5,
		pagesCount: list.data?.pagesCount,
		totalCount: list.data?.totalCount,
	};

	const createFormFields = [
		{
			name: ['role'],
			value: '',
		},
		{
			name: ['state'],
			value: '',
		},
		{
			name: 'createUserId',
			value: user.id,
		},
		{
			name: 'createUserType',
			value: 'dashboard',
		},
	];

	const updateFormFields = [
		{
			name: 'role',
			value: updateUser?.user_role || '',
		},
		{
			name: 'updateUserId',
			value: updateUser._id,
		},
		{
			name: 'firstName',
			value: updateUser.firstName,
		},
		{
			name: 'lastName',
			value: updateUser.lastName,
		},
		{
			name: 'email',
			value: updateUser.email,
		},
		{
			name: 'status',
			value: updateUser.status,
		},
		{
			name: ['phone'],
			value: updateUser.phone,
		},
		{
			name: 'newPassword',
			value: '',
		},
		{
			name: 'useShippingAsBilling',
			value: billingAddress,
		},
		{
			name: 'verified',
			value: updateUser.verified === 'verified' ? true : false,
		},
		{
			name: 'friendsAndFamily',
			value: updateUser.friendsAndFamily,
		},
		{
			name: 'shippingAddress',
			value: userAddresses.find((address) => address.addressType === 'shipping') || {}, // Shipping address
		},
		{
			name: 'billingAddress',
			value: userAddresses.find((address) => address.addressType === 'billing') || {}, // Billing address
		},
	];

	

	const blockFormFields = [
		{
			name: 'blockUserId',
			value: blockUser,
		},
		{
			name: 'status',
			value: blockUser.status,
		},
	];

	const deleteFormFields = [
		{
			name: 'deleteUserId',
			value: employeeDeleteId,
		},
	];

	const statusSingleFormFields = [
		{
			name: 'statusSingleUserId',
			value: statusSingleUser,
		},
		{
			name: 'status',
			value: statusSingleUser.status,
		},
	];

	const statusMultiFormFields = [
		{
			name: 'statusMultiUser',
			value: userStatusMultiId,
		},
	];

	const viewFormProps = {
		viewProps: viewProps,
		viewLoading: viewLoading,
		viewData: viewData,
		handleClose,
	};

	const createFormProps = {
		createProps,
		createModalFormRef,
		handleOnFinish,
		createFormFields,
		roles,
		createBtnLoader,
		setToggleCreate,
	};

	const updateFormAllSettingsProps = {
		updateProps,
		loadingUser,
		updateModalFormRef,
		updateFormFields,
		onChange,
		roles,
		toggleNewPassword,
		editBtnLoader,
		handleOnFinish,
		setToggleUpdate,
		setBillingAddrress,
		handleChangeShipping,
		shippingForm,
		handleChangeBilling,
		billingForm,
		userShippingAddress,
		userBillingAddress,
		handleChangeShipState,
		handleChangeBillState,
		setUserShippingAddress,
		setUserBillingAddress,
		setShippingForm,
    setBillingForm
	};
	const updateUserStatusSingleChangesProps = {
		status: 'status',
		statusSingleProps,
		statusModalSingleFormRef,
		handleOnFinish,
		statusSingleFormFields,
		setToggleStatusSingle,
		deactivateBtnLoader,
	};

	const updateUserStatusBlockChangesProps = {
		status: 'block',
		statusSingleProps: blockProps,
		statusModalSingleFormRef,
		handleOnFinish,
		statusSingleFormFields,
		setToggleStatusSingle,
		deactivateBtnLoader,
	};

	const deleteFormProps = {
		deleteProps,
		deleteModalFormRef,
		handleOnFinish,
		deleteFormFields,
		deleteBtnLoader,
		setToggleDelete,
	};

	const blockFormProps = {
		blockProps,
		blockModalFormRef,
		handleOnFinish,
		blockFormFields,
		blockBtnLoader,
		setToggleBlock,
	};

	return (
		<>
			<h2 className="page-title">Users</h2>

			<Section className="section section__wrapper section__users">
				{/* Main Header */}
				<header className="section__header">
					{!loading ? (
						<div className="filter">
							<span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
							<SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setRefetch} loading={loading} />
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
					)}

					{!loading ? (
						<div className="search">
							<form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
								<div className="form-group m-0">
									<span className="icon icon-search">
										<img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
									</span>
									<input value={searchForm.query || ''} type="text" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
									<span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
										<img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
									</span>
								</div>
								<button className="form-submit" data-cy="search-submit-btn" disabled={!searchForm.query || searchForm.query.length === 0}>
									Search
								</button>
							</form>
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
					)}
				</header>

				{/* Main Content */}
				<Main className="section__content relative min-h-table-content">{loading ? <Loading /> : <Table data={list?.data?.Data} {...tableProps} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>

				{/* Create User - all settings */}
				{getGroceryPermissions(groceryPermissions, PERMISSIONS.createCustomers) && <CreateForm {...createFormProps} />}

				<ViewUserData {...viewFormProps} />

				{/* Update User - all settings */}
				<UpdateFormAllSettings {...updateFormAllSettingsProps} />

				<UpdateUserStatusSingleChanges {...updateUserStatusSingleChangesProps} />
				{/* Delete User - delete */}
				<DeleteForm {...deleteFormProps} />

				<BlockForm {...blockFormProps} />
			</Section>
		</>
	);
};

export default Users;
