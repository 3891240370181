import React, { useEffect, useRef, useState } from 'react';
import { useProducts } from '../../../../../../context/products';
import { notification } from 'antd';

// GENERATED OTP
const generateOtp = () => {
	return Math.floor(1000 + Math.random() * 9000);
};

const loadingContainer = {
	width: '100%',
	textAlign: 'center',
	display: 'grid',
	alignItems: 'center',
	columnGap: '15px',
	gridTemplateColumns: '24px',
};

const loadingAnimation = {
	width: '20px',
	height: '20px',
	border: '2px solid #e7e7e7',
	borderTop: '2px solid #4761e6',
	borderRadius: '50%',
	animation: 'spin 1s linear infinite',
	margin: 'auto',
	display: 'block',
};

function LoadingSpinner() {
	return (
		<div style={loadingContainer}>
			<span style={loadingAnimation} />
		</div>
	);
}

const colorOptions = ['black', 'white', 'multi', 'grey', 'yellow', 'brown', 'blue', 'orange', 'pink', 'purple', 'red', 'green'];
// CREATE ATTRIBUTE
export const Attributes = (props) => {
	const { form, formError, onChange, setSelectedSizes, selectedSizes, setColors, colors, setGender, gender, sizeChartEntries } = useProducts();
	const [activeTab, setActiveTab] = useState('male');
	const [selectSizes, setSelectSizes] = useState([]);
	const [genders, setGenders] = useState([
		{ _id: 'male', name: 'Male' },
		{ _id: 'female', name: 'Female' },
		{ _id: 'children', name: 'Children' },
	]);
	console.log(sizeChartEntries);

	const isSizeSelected = (size) => selectedSizes.includes(size);

	const handleGender = (e) => {
		const selectedGender = e.target.value;
		setGender(selectedGender);
		// setSelectedSizes([]);
	};

	const handleAddColor = (color) => {
		if (color && !colors.includes(color)) {
			setColors(color);
		} else {
			notification.error({ message: 'Color is empty, invalid or already exists.' });
		}
	};

	const handleCheckboxChange = (size, isChildSize) => {
		setSelectedSizes((prevSizes) => {
			// Find the corresponding usMens value based on the size
			const usMensValue = sizeChartEntries.find((entry) => (isChildSize ? entry.usChldrns === size : entry.usMens === size))?.usMens; // Get the usMens value

			if (usMensValue) {
				if (prevSizes.includes(usMensValue)) {
					// If the size is already selected, remove it
					return prevSizes.filter((s) => s !== usMensValue);
				} else {
					// Otherwise, add it to the selected sizes
					return [...prevSizes, usMensValue];
				}
			}
			return prevSizes; // Return previous sizes if no usMens value found
		});
	};

	return (
		<div className="product__card attributes product__card--info">
			<div className="wrapper-element p-0">
				<header className="card-header">
					<h4>Product More Info</h4>
				</header>
				<main className="card-content relative">
					<form name="product-info" className="form">
						<div className="form-group">
							<label className="label" htmlFor="colorWay">
								<b>ColorWay:</b>
							</label>
							<input value={form.productColorWay || ''} onChange={onChange} name="productColorWay" placeholder="Colorway" type="text" id="colorWay" className={`input  ${formError.productColorWay ? 'error' : ''}`} />
						</div>
						<div className="form-group">
							<label className="label" htmlFor="productFactoryColor">
								<b>Factory color:</b>
							</label>
							<input value={form.productFactoryColor || ''} onChange={onChange} name="productFactoryColor" placeholder="Factory Color" type="text" id="factoryColor" className={`input  ${formError.productFactoryColor ? 'error' : ''}`} />
						</div>

						<div className="form-group colors">
							<label className="label" htmlFor="productFactoryColor">
								<b>Choose Color:</b>
							</label>
							<select
								className={`select ${formError.productColors ? 'error' : ''}`}
								onChange={(e) => {
									const selectedColor = e.target.value;
									// Only call handleAddColor if the selected color is valid
									if (selectedColor) {
										handleAddColor(selectedColor);
									}
								}}
								value={form.productColors}
							>
								<option value="" disabled>
									Select a Color
								</option>
								{colorOptions.map((color) => (
									<option key={color} value={color}>
										{color.charAt(0).toUpperCase() + color.slice(1)} {/* Capitalize the first letter */}
									</option>
								))}
							</select>
							<p data-cy="" className={`error__onblur ${formError.productColors ? 'error' : ''}`}>
								* This field is required!
							</p>
						</div>

						<div className="form-group">
							<label className="label" htmlFor="style">
								<b>Style/Season:</b>
							</label>
							<input value={form.productStyleSeason || ''} onChange={onChange} name="productStyleSeason" placeholder="Style/Season" type="text" id="style" className={`input  ${formError.productStyleSeason ? 'error' : ''}`} />
						</div>
						<div className="form-group">
							<label className="label" htmlFor="gender">
								<b>Gender:</b>
							</label>
							<select value={gender || ''} onChange={handleGender} name="productGender" id="productGender" className={`select ${formError.productGender ? 'error' : ''}`}>
								<option value="" disabled>
									Select a Gender
								</option>
								{genders.map((gender) => (
									<option key={gender._id} value={gender.name}>
										{gender.name}
									</option>
								))}
							</select>
							<p data-cy="" className={`error__onblur ${formError.productGender ? 'error' : ''}`}>
								* This field is required!
							</p>
						</div>
						<div className="form-group">
							<label className="label" htmlFor="sizes">
								<b>Available Sizes:</b>
							</label>

							<div className="checkbox-group">
								{sizeChartEntries.map((entry, index) => {
									return (
										<div key={index} className="item">
											<label>
												<input name="productAvailableSizes" type="checkbox" checked={isSizeSelected(entry.usMens)} onChange={() => handleCheckboxChange(entry.usMens, false)} />
												<b>{entry.usChldrns ? entry.usChldrns : entry.usMens}</b>

												<span className="equivalent-size">
													(Eq: {entry.usChldrns && `${entry.usMens} and `} {entry.usWmns} )
												</span>
											</label>
										</div>
									); // If neither condition matches, return null
								})}
							</div>
						</div>
					</form>
				</main>
			</div>
		</div>
	);
};
